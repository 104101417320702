<!--
  base64编码 组件
-->
<template>
	<div class="md5-tools-page">
		<md5-header />
		<main class="md5-tools-box">
			<div class="base64-component">
				<a
					href="javascript:history.back(-1)"
					rel="noopener noreferrer"
					class="goback"
					title="返回上一页"
				>
					<i class="el-icon-back"></i>
					返回
				</a>
				<h3 class="title">{{ h3 }}</h3>
				<el-row>
					<el-col :span="24">
						<el-tabs v-model="type" type="border-card" @tab-click="handleClick">
							<el-tab-pane label="图片->base64编码" name="base64">
								<el-row>
									<el-col :span="24" class="col">
										<el-upload
											class="upload-demo"
											drag
											action
											ref="uploadAvatar"
											:show-file-list="false"
											:auto-upload="false"
											:on-change="changeFile"
										>
											<img
												v-if="imageUrl"
												:src="imageUrl"
												class="uploadAvatar"
											/>
											<div v-else>
												<i class="el-icon-upload"></i>
												<div class="el-upload__text">
													将文件拖到此处，或
													<em>点击上传</em>
												</div>
												<div class="el-upload__tip" slot="tip">
													只能上传jpg/png文件，且不超过2MB
												</div>
											</div>
										</el-upload>
									</el-col>
									<el-col :span="24" class="col content-button">
										<div class="submit" @click="copy">
											<a href="javascript:void(0)">复制结果</a>
										</div>
										<div class="submit" @click="clear">
											<a href="javascript:void(0)">清空结果</a>
										</div>
									</el-col>
									<el-col :span="24" class="col result-textarea">
										<el-input
											type="textarea"
											v-model.trim="imageBaseUrl"
											rows="10"
											placeholder="结果"
										></el-input>
									</el-col>
								</el-row>
							</el-tab-pane>
							<el-tab-pane label="base64编码->图片" name="image">
								<el-row>
									<el-col :span="24" class="col result-textarea">
										<el-input
											type="textarea"
											v-model.trim="resultUrl"
											rows="10"
											placeholder="请输入base64编码"
										></el-input>
									</el-col>
									<el-col :span="24" class="col content-button">
										<div class="submit" @click="decode">
											<a href="javascript:void(0)">转换图片</a>
										</div>
										<div class="submit" @click="clear">
											<a href="javascript:void(0)">清空输入框</a>
										</div>
									</el-col>
									<el-col :span="24" class="col result-content">
										<div class="result-img-content">
											<img
												v-show="resultImageUrl"
												:src="resultImageUrl"
												class="result-img"
											/>
										</div>
									</el-col>
								</el-row>
							</el-tab-pane>
						</el-tabs>
					</el-col>
				</el-row>
			</div>
		</main>
		<md5-footer />
	</div>
</template>

<script>
import md5Header from './../../components/Header';
import md5Footer from './../../components/Footer';
export default {
	name: 'base64',
	components: {
		md5Header,
		md5Footer,
	},
	data() {
		return {
			imageUrl: '',
			imageBaseUrl: '',
			resultImageUrl: '',
			resultUrl: '',
			type: 'base64',
			h3: this.$route.query.id,
		};
	},
	methods: {
		clear() {
			this.imageBaseUrl = '';
			this.resultUrl = '';
			this.imageUrl = '';
			this.resultImageUrl = '';
		},
		copy() {
			this.copyData(this.imageBaseUrl);
			document.querySelector('.result-textarea .el-textarea__inner').select(); //复制 获得焦点
		},
		decode() {
			this.resultImageUrl = this.resultUrl;
		},
		handleClick() {
			this.clear();
		},
		changeFile(file, fileList) {
			console.log(fileList);
			const isJPGORPNG =
				file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
			const isLt1M = file.size / 1024 / 1024 < 2;
			if (!isJPGORPNG) {
				this.$message.info('上传图片只能是 JPG 或 PNG 格式!');
				return;
			}
			if (!isLt1M) {
				this.$message.info('上传图片大小不能超过 2MB!');
				return;
			}
			const _this = this;
			const reader = new FileReader();
			reader.readAsDataURL(file.raw);
			reader.onload = function (e) {
				console.log(e.target == this);
				// this.result
				_this.imageBaseUrl = this.result;
				_this.imageUrl = this.result;
			};
		},
	},
};
</script>

<style lang="scss" scoped>
.goback {
	float: right;
	font-size: 16px;
	font-weight: 400;
	color: rgba(68, 169, 254, 1);
	cursor: pointer;
	display: flex;
	align-items: center;
	margin-right: 20px;
	i {
		font-size: 18px;
	}
	&:hover {
		color: #37bef0;
	}
}
.md5-tools-page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	background: url(../../assets/img/cmd-index-bg.png) no-repeat center;
	background-size: cover;
	background-attachment: fixed;
	.md5-tools-box {
		flex: 1;
		padding-top: 30px;
		width: 1208px;
		margin: 0 auto;
	}
	.title {
		width: 100%;
		height: 60px;
		font-size: 30px;
		font-weight: bold;
		color: #333333;
		line-height: 36px;
		text-align: center;
	}

	.base64-component {
		width: 100%;
		margin-top: 10px;
		height: auto;
		background: #ffffff;
		box-shadow: 0px 3px 26px 3px rgba(35, 24, 21, 0.1);
		padding: 35px;
		border-radius: 6px;
		.upload-demo {
			height: 250px;
			display: flex;
			justify-content: center;
			/deep/ .el-upload-dragger {
				height: 100%;
				// width: 100%;
			}
		}
		.uploadAvatar {
			height: 100%;
			width: 100%;
		}
		.result-content {
			height: 250px;
			display: flex;
			justify-content: center;
		}
		.result-img-content {
			background-color: #fff;
			border: 1px dashed #d9d9d9;
			border-radius: 6px;
			box-sizing: border-box;
			width: 360px;
			text-align: center;
			position: relative;
			overflow: hidden;
			.result-img {
				width: 100%;
				height: 100%;
			}
		}
		.col {
			margin-top: 20px;
			.content-input {
				width: 50%;
			}
		}

		.content-button {
			display: flex;
			justify-content: center;
			align-items: center;
			.submit {
				text-align: center;
				padding-left: 30px;
			}
			.submit a {
				display: inline-block;
				background: linear-gradient(0deg, #ffffff, #f9f9f9);
				border: 2px solid #ffffff;
				box-shadow: 0px 3px 9px 1px rgba(34, 23, 20, 0.1);
				border-radius: 4px;
				padding: 0 18px;
				font-size: 14px;
				font-weight: 500;
				color: #3271ec;
				line-height: 36px;
				transition: all 0.3s;
				&:hover {
					color: #37bef0;
					i {
						color: #37bef0;
					}
				}
				&:active {
					background: #3271ec;
					border-color: #3271ec;
					color: #ffffff;
				}
			}
		}
		/deep/ .el-input__inner:focus {
			border-color: #409eff !important;
			outline: 0;
		}
		/deep/ .el-input__inner {
			border: 1px solid #dcdfe6 !important;
		}
		/deep/ .el-textarea__inner {
			font-family: '';
			resize: none;
		}
		.warn-active {
			/deep/ .el-textarea__inner {
				border: 1px solid #f56c6c;
			}
		}
		.warn-active-input {
			/deep/ .el-input__inner {
				border: 1px solid #f56c6c;
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scaleX(1);
	}

	50% {
		transform: scale3d(1.03, 1.03, 1.03);
	}

	to {
		transform: scaleX(1);
	}
}
</style>
